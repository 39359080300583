import PropTypes from 'prop-types';
import React, { FC } from 'react';
import BreadcrumbsWidget from '../breadcrumbs-widget';
import DesktopHeader from '../desktop-header';
import { renderSearchInput } from './render-search-input';
import HeaderTabs from '../header-tabs/header-tabs.component';
import { areBreadcrumbsVisible, getActiveFeedName, MainPageType } from '../header-tabs';
import { connect } from '../../../common/components/runtime-context';
import { flowRight } from 'lodash';
import { RootState } from '../../reducers/root-state';
import { getIsMainPageEnabled } from '../../selectors/app-settings-selectors';
import withAuth from '../../hoc/with-auth';
import { getCategories } from '../../../common/selectors/categories-selectors';
import classNames from 'classnames';
import styles from './internal-page-tabs-header.scss';
import Breadcrumbs from '../../components/breadcrumbs';

interface InternalPageTabsDesktopHeaderProps {
  params: any;
  routes: any;
  isAuthenticated: boolean;
  activeFeedType: string;
  isCategoriesPageVisible: boolean;
  isCategoryPage: boolean;
  mainPageType: MainPageType;
  areBreadcrumbsVisible: boolean;
}

const InternalPageTabsDesktopHeader: FC<InternalPageTabsDesktopHeaderProps> = ({
  params,
  routes,
  mainPageType,
  isAuthenticated,
  activeFeedType,
  isCategoriesPageVisible,
  areBreadcrumbsVisible,
  ...props
}) => {
  const breadcrumbsContainerClasses = classNames(styles.container, 'forum-card-border-color');

  return (
    <>
      <DesktopHeader
        left={
          <HeaderTabs
            isAuthenticated={isAuthenticated}
            mainPageType={mainPageType}
            activeFeedType={activeFeedType}
            isCategoriesPageVisible={isCategoriesPageVisible}
          />
        }
        right={<BreadcrumbsWidget searchInput={renderSearchInput()} params={params} />}
        {...props}
      />
      {areBreadcrumbsVisible && (
        <DesktopHeader
          className={breadcrumbsContainerClasses}
          size="medium"
          left={<Breadcrumbs routes={routes} params={params} />}
        />
      )}
    </>
  );
};

InternalPageTabsDesktopHeader.propTypes = {
  params: PropTypes.object.isRequired,
};

const mapRuntimeToProps = (state: RootState, _: any, __: any, host: any) => {
  const isMainPageCategoriesList = getIsMainPageEnabled(state, host.style);
  const mainPageType = isMainPageCategoriesList ? MainPageType.Categories : MainPageType.Posts;

  return {
    mainPageType,
    isCategoriesPageVisible: getCategories(state).length > 1,
    activeFeedType: getActiveFeedName(state, mainPageType),
    areBreadcrumbsVisible: areBreadcrumbsVisible(state),
  };
};

export default flowRight(connect(mapRuntimeToProps), withAuth)(InternalPageTabsDesktopHeader);
