import { FeedType } from './feed-type.enum';
import { RootState } from '../../reducers/root-state';
import { getRoute, getRouteParams } from '../../../common/router/router-selectors';
import { get } from 'lodash';
import { FEED_QUERY_PARAM_NAME } from './constants';
import { getQuery } from '../../../common/store/location/location-selectors';
import { MainPageType } from './main-page-type.enum';
import {
  ROUTE_CATEGORY,
  ROUTE_CATEGORY_CREATE_POST,
  ROUTE_CATEGORY_CREATE_QUESTION,
  ROUTE_CREATE_POST,
  ROUTE_CREATE_QUESTION,
  ROUTE_POST,
  ROUTE_POST_EDIT,
  // @ts-expect-error
} from '@wix/communities-forum-client-commons/dist/src/constants/routes';
import { getCurrentUser } from '../../../common/store/current-user/current-user-selectors';

export const getHeaderTabs = (state: RootState) => state.headerTabs;

export const getActiveTabName = (state: RootState): FeedType | null =>
  getHeaderTabs(state).activeTab;

export const getFeedType = (state: RootState): FeedType =>
  get(getQuery(state), FEED_QUERY_PARAM_NAME);

export const getActiveFeedName = (state: RootState, mainPageType: MainPageType): FeedType => {
  const categorySlug = getRouteParams(state).categorySlug;
  const postSlug = getRouteParams(state).postSlug;

  if (categorySlug && !postSlug) {
    return FeedType.Categories;
  }

  const activeTabName = getActiveTabName(state);

  if (activeTabName) {
    return activeTabName;
  }

  return {
    [MainPageType.Categories]: FeedType.Categories,
    [MainPageType.Posts]: FeedType.Posts,
  }[mainPageType];
};

export const areBreadcrumbsVisible = (state: RootState): boolean => {
  const route = getRoute(state);

  return [
    ROUTE_POST,
    ROUTE_CATEGORY,
    ROUTE_CREATE_POST,
    ROUTE_CREATE_QUESTION,
    ROUTE_CATEGORY_CREATE_POST,
    ROUTE_CATEGORY_CREATE_QUESTION,
    ROUTE_POST_EDIT,
  ].includes(route);
};

export const getActiveSiteMemberId = (state: RootState): string | undefined => {
  const currentSiteMemberId = getCurrentUser(state)?.siteMemberId;
  const activeTabName = getActiveTabName(state);

  if (activeTabName === FeedType.MyPosts && currentSiteMemberId) {
    return currentSiteMemberId;
  }
};
