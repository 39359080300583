import React, { FC } from 'react';
import { TEXT_BUTTON_PRIORITY, TextButton } from 'wix-ui-tpa/TextButton';
import { classes } from './header-tabs.st.css';
import { MainPageType } from './main-page-type.enum';
import withTranslate from '../../../common/components/with-translate/with-translate';
import { FeedType } from './feed-type.enum';
import Link from '../../components/link/internal-link';
import { createFeedTypeQueryParam } from './create-feed-type-query-param';
import { TFunction } from '@wix/wix-i18n-config';

interface HeaderTabsProps {
  t: TFunction;
  isAuthenticated: boolean;
  mainPageType: MainPageType;
  activeFeedType: string;
  isCategoriesPageVisible: boolean;
}

type HeaderTabItem = {
  title: string;
  isVisible: boolean;
  isActive: boolean;
  feedType: FeedType | null;
};

const HeaderTabs: FC<HeaderTabsProps> = ({
  t,
  isAuthenticated,
  mainPageType,
  activeFeedType,
  isCategoriesPageVisible,
}) => {
  const isMainPageCategories = mainPageType === MainPageType.Categories;

  const categoriesItem: HeaderTabItem = {
    title: t('tabs.categories'),
    isVisible: isCategoriesPageVisible,
    isActive: activeFeedType === FeedType.Categories,
    feedType: isMainPageCategories ? null : FeedType.Categories,
  };

  const postsItem: HeaderTabItem = {
    title: t('tabs.posts'),
    isVisible: true,
    isActive: activeFeedType === FeedType.Posts,
    feedType: isMainPageCategories ? FeedType.Posts : null,
  };

  const myPostsItem: HeaderTabItem = {
    title: t('tabs.my-posts'),
    isVisible: isAuthenticated,
    isActive: activeFeedType === FeedType.MyPosts,
    feedType: FeedType.MyPosts,
  };

  const items = [
    isMainPageCategories ? categoriesItem : postsItem,
    isMainPageCategories ? postsItem : categoriesItem,
    myPostsItem,
  ].filter(({ isVisible }) => isVisible);

  return (
    <nav>
      <ul className={classes.tabs}>
        {items.map(({ title, isActive, feedType }) => (
          <li className={classes.tabsItem}>
            <Link to={`${feedType ? `?${createFeedTypeQueryParam(feedType)}` : ''}`}>
              <TextButton
                className={classes.button}
                priority={isActive ? TEXT_BUTTON_PRIORITY.primary : TEXT_BUTTON_PRIORITY.secondary}
              >
                {title}
              </TextButton>
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default withTranslate(HeaderTabs);
